import React, { Component } from 'react';
import PropTypes from 'prop-types';
import QuestionType from "./QuestionType";
import { AiFillInfoCircle } from "react-icons/all";
import Handlebars from "handlebars";

class QuestionHelptext extends QuestionType {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {}

    getParsedHtml() {
        const data = this.question().htmltext;
        try {
            const template = Handlebars.compile(data ? data : '');
            const websiteState = this.qCtrl().formular.state.website;
            const zohoData = this.qCtrl().getZohoCustomerData();
            const d = template({ websiteState, zohoData });
            return d;
        } catch (e) {
            console.error('Error compiling HTML template:', e);
            return data; // Return original data in case of error
        }
    }

    render() {
        const { darkMode } = this.props;
        const containerClasses = `helptext-container ${this.hasError() ? 'border-red-500' : 'border'} ${darkMode ? 'bg-gray-800 text-white border-gray-600' : 'bg-gray-50 text-gray-700 border-gray-300'} rounded-lg p-1`;

        return (
          <div className={containerClasses}>
              <div className="p-1 flex items-center justify-between">
                  <span dangerouslySetInnerHTML={{ __html: this.getParsedHtml() }}></span>
                  <AiFillInfoCircle size={36} className={`inline-block ml-2 ${darkMode ? 'text-gray-300' : 'text-purple-600'}`} />
              </div>
          </div>
        );
    }
}

QuestionHelptext.propTypes = {
    darkMode: PropTypes.bool,
};

QuestionHelptext.defaultProps = {
    darkMode: false,
};

export default QuestionHelptext;
