import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { BiInfoCircle, BiReset } from 'react-icons/bi';
import { AiFillInfoCircle } from 'react-icons/all';
import AirtableHelper from '../../helper/AirtableHelper';

// Import der Frage-Typen (angenommen, dies sind Komponenten, die Sie definiert haben)
import QuestionYesNo from '../questiontypes/QuestionYesNo';
import QuestionMultipleChoice from '../questiontypes/QuestionMultipleChoice';
import QuestionText from '../questiontypes/QuestionText';
import QuestionDomain from '../questiontypes/QuestionDomain';
import QuestionRichText from '../questiontypes/QuestionRichText';
import QuestionColor from '../questiontypes/QuestionColor';
import QuestionSingleChoice from '../questiontypes/QuestionSingleChoice';
import QuestionLink from '../questiontypes/QuestionLink';
import QuestionWYSIWYG from '../questiontypes/QuestionWYSIWYG';
import QuestionFile from '../questiontypes/QuestionFile';
import QuestionWebsitePreview from '../questiontypes/QuestionWebsitePreview';
import QuestionNumber from '../questiontypes/QuestionNumber';
import QuestionAutosave from '../questiontypes/QuestionAutosave';
import QuestionRanking from '../questiontypes/QuestionRanking';
import QuestionHtml from '../questiontypes/QuestionHtml';
import QuestionRepeated from '../questiontypes/QuestionRepeated';
import QuestionParameter from '../questiontypes/QuestionParameter';
import QuestionHelptext from '../questiontypes/QuestionHelptext';
import QuestionRegex from '../questiontypes/QuestionRegex';
import QuestionAddProduct from '../questiontypes/QuestionaddProduct';

class Question extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionRef: null,
      showModal: false,
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.onResetQuestion = this.onResetQuestion.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  qCtrl() {
    return this.props.qctrl;
  }

  question() {
    return this.props.question;
  }

  isVisibleByCondition() {
    return this.qCtrl().checkCondition(this.question());
  }

  renderQuestionByType() {
    try {
      const addProps = {
        setRef: (ref) => this.setState({ questionRef: ref }),
      };
      const type = this.question().Datentyp.toLowerCase().trim();
      switch (type) {
        case 'bool':
        case 'boolean':
          return <QuestionYesNo {...addProps} {...this.props} />;
        case 'html':
          return <QuestionHtml {...addProps} {...this.props} />;
        case 'ranking':
          return <QuestionRanking {...addProps} {...this.props} />;
        case 'link':
          return <QuestionLink {...addProps} {...this.props} />;
        case 'multiselect':
        case 'model':
          return <QuestionMultipleChoice {...addProps} {...this.props} />;
        case 'autosave':
          return <QuestionAutosave {...addProps} {...this.props} />;
        case 'select':
          return <QuestionSingleChoice {...addProps} {...this.props} />;
        case 'string':
          return <QuestionText {...addProps} {...this.props} />;
        case 'number':
          return <QuestionNumber {...addProps} {...this.props} />;
        case 'parameter':
          return <QuestionParameter {...addProps} {...this.props} />;
        case 'domain':
          return <QuestionDomain {...addProps} {...this.props} />;
        case 'addproduct':
          return <QuestionAddProduct {...addProps} {...this.props} />;
        case 'wysiwyg':
        case 'content':
          return <QuestionWYSIWYG {...addProps} {...this.props} />;
        case 'helptext':
          return <QuestionHelptext {...addProps} {...this.props} />;
        case 'richtext':
          return <QuestionRichText {...addProps} {...this.props} />;
        case 'repeated':
          return <QuestionRepeated {...addProps} {...this.props} />;
        case 'color':
          return <QuestionColor {...addProps} {...this.props} />;
        case 'file':
          return <QuestionFile {...addProps} {...this.props} />;
        case 'regex':
          return <QuestionRegex {...addProps} {...this.props} />;
        case 'websitepreview':
          return <QuestionWebsitePreview {...addProps} {...this.props} />;
        default:
          return type;
      }
    } catch (e) {
      console.log('Can not render the datatype for: ', this.question());
      return null;
    }
  }

  renderBadge() {
    const q = this.question();
    if (q.Badge) {
      return (
        <div className="question-badge">
          <span>{q.Badge}</span>
        </div>
      );
    }
    return null;
  }

  renderSubText() {
    const q = this.question();
    if (q.Subtext) {
      return AirtableHelper.createElementByHtmlRTF(q.Subtext);
    }
    return null;
  }

  renderAdditionalCss() {
    const q = this.question();
    if (q.additionalCss) {
      return (
        <style
          dangerouslySetInnerHTML={{ __html: q.additionalCss }}
          id="additionalCss"
          type="text/css"
        />
      );
    }
    return null;
  }

  renderAdditionalHtml() {
    const q = this.question();
    if (q.additionalHtml) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: this.qCtrl().replaceTemplatePlaceholders(q.additionalHtml, q),
          }}
        />
      );
    }
    return null;
  }

  toggleModal() {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
  }

  onResetQuestion() {
    if (this.state.questionRef) {
      this.state.questionRef.onResetQuestion();
    }
    this.qCtrl().resetQuestion(this.question());
  }

  handleReset() {
    this.onResetQuestion();
    this.toggleModal();
  }

  renderModal() {
    const { showModal } = this.state;
    if (!showModal) {
      return null;
    }

    return (
      <div className="question-modal fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50">
        <div className="question-modal-content bg-white dark:bg-gray-800 rounded-lg p-6 max-w-md">
          <h4 className="text-lg font-medium mb-2">Frage zurücksetzen</h4>
          <p className="text-sm text-gray-700 dark:text-white mb-4">
            Möchtest du wirklich deine Eingabe zurücksetzen?
          </p>
          <div className="flex justify-end space-x-2">
            <button
              className="px-4 py-2 bg-gray-200 text-gray-800 dark:bg-gray-700 dark:text-white rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
              onClick={this.handleReset}
            >
              Zurücksetzen
            </button>
            <button
              className="px-4 py-2 bg-gray-200 text-gray-800 bg-white dark:bg-gray-700 dark:text-white rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
              onClick={this.toggleModal}
            >
              Abbrechen
            </button>
          </div>
        </div>
      </div>
    );
  }

  isRequired() {
    return this.qCtrl().isQuestionRequired(this.question());
  }

  requiredText() {
    return this.isRequired() ? <b className="text-red-500">*</b> : null;
  }

  render() {
    const t = this.props.t;
    const question = this.question();
    return (
      this.isVisibleByCondition() && (
        <div className="single-question-box mb-4 p-4 bg-white dark:bg-gray-900 rounded-lg shadow-md">
          <div className="flex justify-between items-center">
            <div className="flex-1">
              <label className="block mb-2 text-md font-medium text-gray-900 dark:text-white">
                {t(question.Text)}
                {this.requiredText()}
              </label>
              {!question.tooltipdisabled && question.Subtext && (
                <div
                  id={`questiontooltip-${question.id}`}
                  className="tooltip-info inline-block ml-2"
                >
                  <AiFillInfoCircle size={24} className="text-purple-600" />
                </div>
              )}
              {this.renderAdditionalCss()}
              {this.renderBadge()}
              {question.tooltipdisabled && this.renderSubText()}
              <div className="mt-2">{this.renderQuestionByType()}</div>
              {this.renderAdditionalHtml()}
            </div>
            {this.qCtrl().canReset(question) && (
              <div className="ml-4">
                {this.renderModal()}
                <button
                  onClick={this.toggleModal}
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-white"
                >
                  <BiReset size={24} />
                </button>
              </div>
            )}
          </div>
        </div>
      )
    );
  }
}

Question.propTypes = {
  qctrl: PropTypes.object.isRequired,
  question: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Question);
