import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SubNavigation from '../components/navigation/SubNavigation';
import ProjectNavigation from '../components/navigation/ProjectNavigation';
import MainNavigation from '../components/navigation/MainNavigation';
import FormularSite from '../components/formular/FormularSite';
import QuestionController from '../controller/QuestionController';
import LoadingOverlay from 'react-loading-overlay';
import DBService from '../services/DBService';
import ResultsPage from '../components/results/ResultsPage';
import FinishedHeader from '../components/finished/FinishedHeader';
import EditModeHeader from '../components/finished/EditModeHeader';
import { WEBCONFIG_TRADE_MODE } from '../config.jsx';
import FloatingCommentButton from '../components/FloatingCommentButton';
import Lottie from 'react-lottie';
import Universal20Service, { saveAccessToken } from '../services/Universal20Service';
import BuildInfo from '../components/specials/BuildInfo';
import { Line } from "rc-progress";
import isFastNet from 'isfastnet';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: require('./saving.json'),
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

class FormularPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      name: '',
      error: null,
      errorWhileSaving: false,
      finished: false,
      editMode: false,
      internetSpeedOkay: false,
      unsavedAnswers: {},
      lastLoadingMessage: null,
      reloadTime: new Date().getTime(),
      websitePreview: null,
      currentCommentQuestion: null,
      currentQuestion: null,
      lastFieldChanged: {
        field: null,
        changeDate: new Date(),
      },
      zohoCustomerData: null,
      showLoginScreen: false,
      categories: [],
      configuration: {},
      agenturTodos: {},
      static_values: {},
      craftsmenTodos: {},
      flatQuestions: {},
      saved: true,
      saving: false,
      answers: {},
      dataObject: {},
      summaryConfig: {},
      cid: null,
      options: {},
      showResults: false,
      errors: {},
    };

    this.dbService = null;
    this.questionController = null;
  }

  onBeforeUnload() {
    if (window.progressing === true)
      return 'Du hast nicht gespeichert. Bist du sicher, dass du die Seite schließen willst?';
    return null;
  }

  onMessageReceived(message) {
    if (!message.message && this.state.lastLoadingMessage.message) {
      message.message = this.state.lastLoadingMessage.message;
    }
    this.setState({
      lastLoading: new Date().getTime(),
      lastLoadingMessage: message,
    });
  }

  checkForNetworkConnectivity() {
    const _this = this;
    const noInternet = setTimeout(() => {
      _this.setState({ internetSpeedOkay: false });
    }, 2600);
    isFastNet((value) => {
      clearTimeout(noInternet);
      if (value) {
        if (value.isFast) {
          _this.setState({ internetSpeedOkay: true });
        } else {
          _this.setState({ internetSpeedOkay: false });
        }
      }
    }, {
      timesToTest: 5,
      threshold: 210,
      image: "http://www.google.com/images/phd/px.gif",
      allowEarlyExit: true,
      verbose: true,
    });
  }

  componentDidMount() {
    window.progressing = false;
    this.props.app.loadConfiguration(this, {
      onMessageReceived: this.onMessageReceived.bind(this),
    });
    setInterval(() => this.checkForNetworkConnectivity(), 7000);
    window.onbeforeunload = this.onBeforeUnload;

    window.addEventListener('message', (message) => {
      const msg = message.data;
      if (msg.type === 'webconfig') {
        saveAccessToken(msg.data.access_token);
        this.setState({ showLoginScreen: false });
        this.questionController.saveQuestionsComplete();
      }
    });
  }

  getParametersDetails(parameters) {
    if (this.getDBService()) {
      return this.getDBService().getParameterDetails(parameters).then(d => d.data.data);
    }
  }

  getPageDataByPath(path) {
    return this.getDBService().getPageDataByPath(path);
  }

  saveFormularAnswers(answers, fixed_values, comments) {
    if (this.getDBService()) {
      return new Promise((resolve, reject) => {
        const timeout = setTimeout(
          () => reject(new Error('Leider konnte nicht gespeichert werden. Probieren sie es später nochmal.')),
          60000
        );
        return this.getDBService().saveState(answers, fixed_values, comments)
          .then(d => {
            clearTimeout(timeout);
            resolve(d.data);
          })
          .catch(err => {
            clearTimeout(timeout);
            if (err.request.status === 401) {
              this.questionController.formular.setState({ saved: false, saving: false }, () => {
                this.questionController.saveBeforeRedirectAndRedirect();
              });
            } else {
              reject(err);
            }
          });
      });
    }
    return Promise.resolve();
  }

  onFormularAnswerChangedSuccfessfully(currentAnswer, fixed_values) {
    if (this.getDBService()) {
      return this.getDBService().saveState(currentAnswer, fixed_values, {}).then(() => {});
    }
    return Promise.resolve();
  }

  getDBService() {
    return this.dbService;
  }

  render() {
    if (this.state.loading) {
      return (
        <LoadingOverlay
          active={this.state.loading}
          className="fixed inset-0 flex items-center justify-center bg-white dark:bg-gray-800 z-50"
          spinner={
            this.state.lastLoadingMessage ? (
              <div className="w-3/4 max-w-sm">
                <Lottie options={defaultOptions} height={400} width={400} isStopped={this.state.isStopped} isPaused={this.state.isPaused} />
                <Line percent={(this.state.lastLoadingMessage.progress / this.state.lastLoadingMessage.progressSteps) * 100} trailColor="#D3D3D3" strokeColor="red" />
                {this.state.lastLoadingMessage.message}
              </div>
            ) : null
          }
        >
          <div className="fullscreen-loader">
            <br />
          </div>
        </LoadingOverlay>
      );
    }

    if (this.state.error !== null) {
      return (
        <div className="page-container error-no-cid-container p-0 flex items-center justify-center h-screen bg-white dark:bg-gray-800 text-black dark:text-white">
          <div className="error-message">{this.state.error}</div>
        </div>
      );
    }

    if (this.state.cid === null) {
      return (
        <div className="page-container error-no-cid-container p-0 flex items-center justify-center h-screen bg-white dark:bg-gray-800 text-black dark:text-white">
          <div className="error-message">{this.state.error}</div>
        </div>
      );
    }

    return (
      <div className="page-container p-0 w-full bg-white dark:bg-gray-800 text-black dark:text-white">
        {this.state.saving && (
          <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-75 dark:bg-gray-800 dark:bg-opacity-75 z-50">
            <div className="text-center">
              <h3 className="text-lg font-semibold">Bitte warten, wir speichern deine Inhalte.</h3>
              <small>Es kann einige Zeit dauern bis wir alles gespeichert haben.</small>
              <div>
                <Lottie options={defaultOptions} height={400} width={400} isStopped={this.state.isStopped} isPaused={this.state.isPaused} />
                <p>Wir speichern gerade deine Inhalte und generieren auch deine Seite sowie die Vorschau, damit du dir später die Ergebnisse direkt ansehen kannst.</p>
              </div>
            </div>
          </div>
        )}
        {this.questionController.isFinished() && !this.state.editMode && <FinishedHeader />}
        {this.state.editMode && <EditModeHeader qctrl={this.questionController} />}
        {!this.state.internetSpeedOkay && (
          <div className="networkErrorline">
            <p className="bg-red-100 text-red-800 p-2">Achtung, deine Internetgeschwindigkeit oder Verbindung ist zu langsam oder nicht vorhanden. Bitte prüfe deine Internetverbindung.</p>
          </div>
        )}
        {this.state.errorWhileSaving && (
          <div className="errorLine">
            <p className="bg-yellow-100 text-yellow-800 p-2">Achtung, es konnte nicht gespeichert werden. Diese Änderungen sind noch ungespeichert. Bitte prüfe deine Internetverbindung und drücke erneut auf speichern.</p>
          </div>
        )}
        <ProjectNavigation
          qctrl={this.questionController}
          name={this.state.name}
          onResultsPageClicked={() => this.setState({ showResults: true })}
          categories={this.state.categories}
        />
        {!this.state.showResults && (
          <MainNavigation
            saving={this.state.saving}
            saved={this.state.saved}
            qctrl={this.questionController}
            categories={this.state.categories}
          />
        )}
        {!this.state.showResults && (
          <SubNavigation
            qctrl={this.questionController}
            categories={this.state.categories}
          />
        )}
        {this.state.showResults && (
          <ResultsPage
            qctrl={this.questionController}
            onCloseResults={() => this.setState({ showResults: false })}
          />
        )}
        {!this.state.showResults && (
          <FormularSite
            reloadTime={this.state.reloadTime}
            onResultsPageClicked={() => this.setState({ showResults: true })}
            qctrl={this.questionController}
            categories={this.state.categories}
          />
        )}
        <FloatingCommentButton
          reloadTime={this.state.reloadTime}
          qctrl={this.questionController}
          categories={this.state.categories}
        />
      </div>
    );
  }
}

FormularPage.propTypes = {
  title: PropTypes.string,
};

export default FormularPage;
