import React, { Component } from 'react';
import PropTypes from 'prop-types';
import QuestionType from './QuestionType';
import FormGroup from 'reactstrap/lib/FormGroup';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import AirtableHelper from '../../helper/AirtableHelper';
import { withTranslation } from "react-i18next";
import Select from "react-select";

class QuestionSingleChoice extends QuestionType {
  constructor(props) {
    super(props);
    this.state = {
      options: null
    };
  }

  componentDidMount() {
    this.loadOptions();
  }

  onOptionChanged(e, option) {
    this.setValue(option.id.toLowerCase(), option);
  }

  renderBadge(q) {
    try {
      if (
        typeof q.Badge !== 'undefined' &&
        q.Badge != null &&
        q.Badge.length > 1
      ) {
        return (
          <div className="question-badge">
            <span>{q.Badge}</span>
          </div>
        );
      }
    } catch (e) {}
    return null;
  }

  renderSubText(q) {
    try {
      if (
        typeof q.Subtext !== 'undefined' &&
        q.Subtext != null &&
        q.Subtext.length > 5
      ) {
        return (
          <div className="option-subtext">
            {AirtableHelper.createElementByHtmlRTF(q.Subtext)}
          </div>
        );
      }
    } catch (e) {}
    return null;
  }

  getValue() {
    try {
      let v = super.getValue();
      if (typeof v === 'undefined' || v == null) {
        if (this.hasVorauswahl()) {
          v = this.getVorauswahl();
          this.setValue(v);
        }
      } else {
        if (Array.isArray(v)) {
          if (v.length > 0) {
            v = v[0];
          } else {
            return null;
          }
        }
        v = v.toLowerCase();
      }
      return v;
    } catch (e) {
      return null;
    }
  }

  renderOptionsDefault(opts) {
    const t = this.props.t;
    const list = [];
    for (const o of opts) {
      const checked = this.getValue() === o.id.toLowerCase();
      list.push(
        <div key={o.id}>
          <div className="p-1 option">
            <label className="flex items-center">
              <Input
                type="radio"
                checked={checked}
                disabled={this.isFinished() || this.isDisabled()}
                onChange={(e) => this.onOptionChanged(e, o)}
                name={this.questionName()}
              />
              <span className="ml-2">{t(o.text)}</span>
              {this.renderBadge(o)}
              {checked && this.renderSubText(o)}
            </label>
          </div>
        </div>
      );
    }
    return list;
  }

  getSelectedValueOption() {
    let v = this.getValue();
    if (v) {
      if (Array.isArray(v) && v.length > 0) {
        v = v[0];
      }
      for (const o of this.state.options) {
        if (o.id.toLowerCase() === v) {
          return {
            value: o.id,
            label: o.text,
            element: o
          };
        }
      }
    }
    return null;
  }

  renderOptionsDropdown(opts) {
    const list = [];
    list.push(
      <div key="dropdown">
        <div className="p-1 option single-choice-dropdown">
          <Select
            className=""
            value={this.getValue() ? this.getSelectedValueOption() : null}
            options={opts.map((d) => {
              return {
                value: d.id,
                label: d.text,
                element: d
              };
            })}
            placeholder="Bitte wählen"
            isDisabled={this.isFinished() || this.isDisabled()}
            onChange={(e) => this.onOptionChanged(null, e.element)}
          />
        </div>
      </div>
    );
    return list;
  }

  renderOptions() {
    let list = [];
    let opts = this.state.options;
    if (opts) {
      opts = this.filterOptionsBySearch(opts);
      if (this.props.question.rendertype && this.props.question.rendertype.id === 'dropdown') {
        list = this.renderOptionsDropdown(opts);
      } else {
        list = this.renderOptionsDefault(opts);
      }
    }
    return list;
  }

  render() {
    return (
      <div className="container mx-auto">
        <div className="p-1 md:col-span-12">
          <div className={`p-1 single-choice-container option-containers ${this.hasError() ? 'error' : ''}`}>
            {this.renderOptions()}
            {this.renderErrors()}
          </div>
        </div>
      </div>
    );
  }
}

QuestionSingleChoice.propTypes = {};

export default withTranslation()(QuestionSingleChoice);
