import React, {Component} from 'react';
import PropTypes from 'prop-types';
import QuestionType from "./QuestionType";
import Input from "reactstrap/lib/Input";
import FormGroup from "reactstrap/lib/FormGroup";
import { FaCheck } from 'react-icons/fa';

class QuestionText extends QuestionType {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    getTextValue() {
        let txt = this.getValue();
        if (txt == null && this.hasVorauswahl()) {
            txt = this.getVorauswahl();
            this.setValue(txt);
        }
        if (txt === null) {
            txt = "";
        }
        return txt;
    }


    render() {
        return <div fluid={true} className={"text-container "+(this.hasError() ? "error" : "")}>
            <div>
                <div className={"p-1"} md={12}>
                    {this.isVorauswahl() && <FaCheck className={"vorauswahl_check"} onClick={(e) => this.onVorauswahlCheckClicked(e)}/>}
                    <Input type={"text"} value={this.getTextValue()}
                           className={"block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"}
                           disabled={this.isFinished() || this.isDisabled()}
                           onChange={(e) => this.setValueQuick(e.target.value, null, 1000)}
                           name={"text"}
                           placeholder={this.placeholder()} />
                    {this.renderErrors()}
                </div>
            </div>
        </div>;
    }
};

QuestionText.propTypes = {

};


export default QuestionText;
