import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CommunicationComponent from "../communication/CommunicationComponent";
import { FaCheckCircle } from "react-icons/fa";

class MainNavigation extends CommunicationComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  qCtrl() {
    return this.props.qctrl;
  }

  clickOnMainCategory(c) {
    this.fireEvent("pageChanged");
    this.qCtrl().setCurrentMainCategory(c);
  }

  renderCategories() {
    const categories = this.qCtrl().getAllCategories().filter((c) => this.qCtrl().hasSubcategories(c));
    const currentCategory = this.qCtrl().getCurrentCategory();

    return categories.map((c, index) => {
      const clickable = this.qCtrl().isClickable(c);
      const finished = this.qCtrl().isMainCategoryFinished(c);
      const isActive = currentCategory.id === c.id;
      const isCompleted = finished;

      let stepIconClass = "flex items-center justify-center w-8 h-8 border rounded-full ring-0 ring-white dark:border-gray-400 dark:text-gray-400 cursor-pointer";
      let stepTextClass = "font-medium leading-tight cursor-pointer";

      if (isActive) {
        stepIconClass += " border-blue-600 dark:border-blue-500 text-blue-600 dark:text-blue-500";
      } else {
        stepIconClass += " border-gray-500 dark:border-gray-400 text-gray-500 dark:text-gray-400";
      }

      return (
        <li key={c.id} className="flex items-center space-x-2.5 rtl:space-x-reverse" onClick={() => this.clickOnMainCategory(c)}>
                    <span className={stepIconClass}>
                        {isCompleted ? <FaCheckCircle size={20} className="text-white" /> : <span className="flex items-center justify-center w-8 h-8 border border-gray-500 rounded-full dark:border-gray-400">{index + 1}</span>}
                    </span>
          <span className={stepTextClass}>
                        <h2 className="font-medium leading-tight">{c.name}</h2>
                    </span>
        </li>
      );
    });
  }

  render() {
    return (
      <div className="w-full bg-white dark:bg-gray-800 text-black dark:text-white p-4">
        <div className="flex justify-between items-center">
          <ol className="items-center w-full space-y-4 sm:flex sm:space-x-8 sm:space-y-0 rtl:space-x-reverse">
            {this.renderCategories()}
          </ol>
          {(!this.qCtrl().isFinished() || this.qCtrl().isEditMode()) && (
            <div className="flex justify-end mt-4">
              <button
                onClick={() => this.qCtrl().saveQuestionsComplete()}
                disabled={this.props.saved || this.props.saving}
                className={`save-button bg-yellow-500 text-white px-4 py-2 rounded shadow-md ${this.props.saved ? "bg-yellow-500" : "bg-yellow-400"} hover:bg-yellow-600`}
              >
                {this.props.saving && <div className="rdw-spinner" size="sm"/>} Speichern
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

MainNavigation.propTypes = {
  qctrl: PropTypes.object.isRequired,
  saved: PropTypes.bool,
  saving: PropTypes.bool,
};

export default MainNavigation;
