import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormGroup from 'reactstrap/lib/FormGroup';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';
import QuestionType from './QuestionType';

class QuestionYesNo extends QuestionType {
    constructor(props) {
        super(props);
        this.state = {};
    }

    getValue() {
        let v = super.getValue();
        if (v) {
            if (Array.isArray(v) && v.length > 0) {
                v = v[0];
            }
        }
        if (typeof v === 'undefined' || v == null) {
            if (this.hasVorauswahl()) {
                v = this.getVorauswahl();
                this.setValue(v);
            }
        }
        return v;
    }

    onChangeOption(e, val) {
        this.setValue(val);
        return e;
    }

    isChecked(val) {
        return this.getValue() === val;
    }

    render() {
        return (
          <div className="container mx-auto">
              <div className="p-1 md:col-span-12">
                  <div className={`p-1 yesno-container ${this.hasError() ? 'error' : ''}`}>
                      <div className="option">
                          <label className="flex items-center">
                              <Input
                                disabled={this.isFinished() || this.isDisabled()}
                                type="radio"
                                checked={this.isChecked(true)}
                                onChange={(e) => this.onChangeOption(e, true)}
                                name={`yesno${this.getId()}`}
                              />
                              <span className="ml-2">Ja</span>
                          </label>
                      </div>
                      <div className="option">
                          <label className="flex items-center">
                              <Input
                                disabled={this.isFinished() || this.isDisabled()}
                                type="radio"
                                checked={this.isChecked(false)}
                                onChange={(e) => this.onChangeOption(e, false)}
                                name={`yesno${this.getId()}`}
                              />
                              <span className="ml-2">Nein</span>
                          </label>
                      </div>
                      <div>
                          {this.renderErrors()}
                      </div>
                  </div>
              </div>
          </div>
        );
    }
}

QuestionYesNo.propTypes = {};

export default QuestionYesNo;
