import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FaLessThan } from 'react-icons/fa';
import QuestionGroup from './QuestionGroup';
import Button from 'reactstrap/lib/Button';
import CommunicationComponent from '../communication/CommunicationComponent';

class QuestionPageContainer extends CommunicationComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    qCtrl() {
        return this.props.qctrl;
    }

    jumpToTop() {
        if (typeof document !== 'undefined') {
            document.getElementById('questionwrapper').scrollTo({ top: 0, behavior: 'auto' });
        }
    }

    next() {
        this.fireEvent('pageChanged');
        const errors = this.qCtrl().checkForAnswersBeforeStep();
        if (errors == null) {
            this.qCtrl().nextQuestionPage(() => {
                this.jumpToTop();
            });
        }
    }

    summary(e) {
        this.fireEvent('pageChanged');
        const errors = this.qCtrl().checkForAnswersBeforeStep();
        if (errors == null) {
            this.props.onResultsPageClicked(e);
        }
    }

    back(e) {
        this.fireEvent('pageChanged');
        this.qCtrl().stepBack(() => {
            this.jumpToTop();
        });
    }

    isLastQuestion() {
        return this.qCtrl().isCurrentLastQuestion();
    }

    render() {
        return (
          <div className="questioncontainer p-0 bg-gray-100 dark:bg-gray-800">
              <div className="container mx-auto px-4 py-6">
                  <div className="bg-white dark:bg-gray-700 shadow rounded-md p-4 mb-6">
                      <QuestionGroup {...this.props} />
                  </div>
                  <div className="flex justify-between mt-4">
                      <button onClick={(e) => this.back(e)} className="back-button text-sm text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-white flex items-center">
                          <FaLessThan className="inline-block mr-2" /> Zurück
                      </button>
                      {!this.isLastQuestion() && (
                        <button onClick={(e) => this.next(e)} className="next-button text-sm bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                            Weiter
                        </button>
                      )}
                      {this.isLastQuestion() && (
                        <button onClick={(e) => this.summary(e)} className="summary-button text-sm bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                            Zusammenfassung
                        </button>
                      )}
                  </div>
              </div>
          </div>
        );
    }
}

QuestionPageContainer.propTypes = {
    qctrl: PropTypes.object.isRequired,
    onResultsPageClicked: PropTypes.func.isRequired
};

export default QuestionPageContainer;
