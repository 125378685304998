import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import QuestionType from './QuestionType';

class QuestionMultipleChoice extends QuestionType {
  constructor(props) {
    super(props);
    this.state = {
      options: null,
      modalOpen: false,
      activeTab: 'profile',
      selectedValues: [],
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.modalRef = React.createRef();
    this.handleEscapeKey = this.handleEscapeKey.bind(this);
    this.onOptionChanged = this.onOptionChanged.bind(this); // Methode binden
  }

  componentDidMount() {
    this.loadOptions();
    document.addEventListener('keydown', this.handleEscapeKey);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleEscapeKey);
  }

  handleEscapeKey(event) {
    if (event.key === 'Escape') {
      this.setState({ modalOpen: false });
    }
  }

  toggleModal() {
    this.setState((prevState) => ({
      modalOpen: !prevState.modalOpen,
    }));
  }

  changeTab(tab) {
    this.setState({ activeTab: tab });
  }

  // Methode zur Behandlung der Optionsänderung
  onOptionChanged(event, option) {
    const { selectedValues } = this.state;
    const id = option.id ? option.id.toLowerCase() : option._id ? option._id.toLowerCase() : option;
    const isChecked = event.target.checked;

    if (isChecked) {
      this.setState({
        selectedValues: [...selectedValues, id],
      });
    } else {
      this.setState({
        selectedValues: selectedValues.filter((value) => value !== id),
      });
    }
  }

  renderOptions(options = null, level = 0) {
    const list = [];
    let opts = options ? options : this.state.options;
    if (opts) {
      opts = this.filterOptionsBySearch(opts);

      for (const o of opts) {
        const id = o.id ? o.id.toLowerCase() : o._id ? o._id.toLowerCase() : o;
        const isChecked = this.state.selectedValues.includes(id);
        list.push(
          <div key={id} className="md:w-1/2 lg:w-1/3 p-2">
            <div className="flex items-center mb-2">
              <input
                disabled={this.isFinished()}
                type="checkbox"
                className="form-checkbox h-4 w-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                checked={isChecked}
                onChange={(e) => this.onOptionChanged(e, o)}
                name={this.questionName()}
                id={id}
              />
              <label
                htmlFor={id}
                className="ml-2 text-sm text-gray-900 dark:text-gray-300 cursor-pointer whitespace-nowrap"
              >
                {o.text ? this.props.t(o.text) : this.props.t(o.label)}
              </label>
            </div>
            <div className={`child-options ml-6 level-${level}`}>
              {o.children && this.renderOptions(o.children, level + 1)}
            </div>
          </div>
        );
      }
    }

    return list;
  }

  renderProfileContent() {
    return (
      <div className="p-4 md:p-5 space-y-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {this.renderOptions()}
        </div>
      </div>
    );
  }

  renderDashboardContent() {
    return (
      <div className="p-4 md:p-5 space-y-4">
        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
          Dashboard content goes here...
        </p>
      </div>
    );
  }

  renderSettingsContent() {
    return (
      <div className="p-4 md:p-5 space-y-4">
        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
          Settings content goes here...
        </p>
      </div>
    );
  }

  renderContactsContent() {
    return (
      <div className="p-4 md:p-5 space-y-4">
        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
          Contacts content goes here...
        </p>
      </div>
    );
  }

  renderTabContent() {
    const { activeTab } = this.state;

    switch (activeTab) {
      case 'profile':
        return this.renderProfileContent();
      case 'dashboard':
        return this.renderDashboardContent();
      case 'settings':
        return this.renderSettingsContent();
      case 'contacts':
        return this.renderContactsContent();
      default:
        return null;
    }
  }

  render() {
    const { activeTab, modalOpen } = this.state;

    return (
      <div>
        <button
          onClick={this.toggleModal}
          className="block text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800"
          type="button"
        >
          Auswahl
        </button>

        {modalOpen && (
          <div
            id="static-modal"
            tabIndex="-1"
            aria-hidden="true"
            className="fixed inset-0 overflow-y-auto z-50 flex justify-center items-center bg-black bg-opacity-50"
          >
            <div className="relative w-full max-w-3xl">
              <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg">
                <div className="flex justify-between items-center p-4 md:p-5 border-b border-gray-200 dark:border-gray-700">
                  <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400 sticky top-0 bg-dark z-10 w-full">
                    <li className="me-2">
                      <button
                        onClick={() => this.changeTab('profile')}
                        className={`inline-block p-4 ${
                          activeTab === 'profile'
                            ? 'text-blue-600 bg-gray-100'
                            : 'hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800'
                        } rounded-t-lg`}
                      >
                        Profile
                      </button>
                    </li>
                    <li className="me-2">
                      <button
                        onClick={() => this.changeTab('dashboard')}
                        className={`inline-block p-4 ${
                          activeTab === 'dashboard'
                            ? 'text-blue-600 bg-gray-100'
                            : 'hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800'
                        } rounded-t-lg`}
                      >
                        Dashboard
                      </button>
                    </li>
                    <li className="me-2">
                      <button
                        onClick={() => this.changeTab('settings')}
                        className={`inline-block p-4 ${
                          activeTab === 'settings'
                            ? 'text-blue-600 bg-gray-100'
                            : 'hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800'
                        } rounded-t-lg`}
                      >
                        Settings
                      </button>
                    </li>
                    <li className="me-2">
                      <button
                        onClick={() => this.changeTab('contacts')}
                        className={`inline-block p-4 ${
                          activeTab === 'contacts'
                            ? 'text-blue-600 bg-gray-100'
                            : 'hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800'
                        } rounded-t-lg`}
                      >
                        Contacts
                      </button>
                    </li>
                  </ul>
                  <button
                    onClick={this.toggleModal}
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    style={{ marginTop: '-0.5rem' }}
                  >
                    <svg
                      className="w-3 h-3"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                <div className="max-h-96 overflow-y-auto">
                  {this.renderTabContent()}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(QuestionMultipleChoice);
