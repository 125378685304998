import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import QuestionType from './QuestionType';
import { FaCheck } from 'react-icons/fa';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

class QuestionWYSIWYG extends QuestionType {
  constructor(props) {
    super(props);
    this.state = {
      text: null,
    };
    this.fireUpdate = null;
  }

  componentDidMount() {
    if (this.state.text === null) {
      this.setState({ text: this.getTextValue() });
    }
  }

  getTextValue() {
    let txt = this.getValue();
    if (Array.isArray(txt) && txt.length > 0) {
      txt = txt[0];
    }
    if (txt == null && this.hasVorauswahl()) {
      txt = this.getVorauswahl();
    }
    return txt;
  }

  onEditorChange(text) {
    this.setState({ text: text });
    clearTimeout(this.fireUpdate);
    this.fireUpdate = setTimeout(() => this.setValue(this.state.text), 1500);
  }

  render() {
    return (
      <Container fluid className={`rich-text-container ${this.hasError() ? 'border-red-500' : 'border'} ${this.props.darkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-700'} border-gray-300 rounded-lg`}>
        <Row>
          <Col className="p-1 wysiwyg-editor" md={12}>
            {this.isVorauswahl() && (
              <FaCheck
                className="vorauswahl_check cursor-pointer"
                onClick={(e) => this.onVorauswahlCheckClicked(e)}
              />
            )}
            <ReactQuill
              id="quill-wysiwyg"
              theme="snow"
              placeholder={this.placeholder()}
              value={this.state.text || ''}
              readOnly={this.isFinished() || this.isDisabled()}
              onChange={(text, delta, source, editor) => {
                if (source === 'user') {
                  this.onEditorChange(text);
                }
              }}
              className={`block w-full p-2 mt-2 focus:outline-none ${this.props.darkMode ? 'text-white border-white' : 'border-gray-300'}`}
              style={{ minHeight: '200px' }}
            />
            {this.renderErrors()}
          </Col>
        </Row>
      </Container>
    );
  }
}

QuestionWYSIWYG.propTypes = {
  darkMode: PropTypes.bool,
};

QuestionWYSIWYG.defaultProps = {
  darkMode: false,
};

export default QuestionWYSIWYG;
