import React, { Component } from 'react';
import PropTypes from 'prop-types';
import QuestionType from './QuestionType';
import Input from 'reactstrap/lib/Input';
import { FaCheck } from 'react-icons/fa';

class QuestionRichText extends QuestionType {
    constructor(props) {
        super(props);
        this.state = {};
    }

    getTextValue() {
        let txt = this.getValue();
        if (txt == null && this.hasVorauswahl()) {
            txt = this.getVorauswahl();
        }
        return txt;
    }

    render() {
        return (
          <div className={`container mx-auto rich-text-container ${this.hasError() ? 'error' : ''}`}>
              <div className="p-1 md:col-span-12">
                  {this.isVorauswahl() && (
                    <FaCheck
                      className="vorauswahl_check cursor-pointer"
                      onClick={(e) => this.onVorauswahlCheckClicked(e)}
                    />
                  )}
                  <Input
                    disabled={this.isFinished() || this.isDisabled()}
                    rows={6}
                    type="textarea"
                    value={this.getTextValue()}
                    placeholder={this.placeholder()}
                    onBlur={(e) => this.setValue(e.target.value)}
                    onChange={(e) => this.setValueQuick(e.target.value)}
                    name="text"
                    className="block w-full p-2 mt-2 text-gray-700 border rounded-lg focus:outline-none focus:border-primary"
                  />
                  {this.renderErrors()}
              </div>
          </div>
        );
    }
}

QuestionRichText.propTypes = {};

export default QuestionRichText;
