import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { FaLessThan } from 'react-icons/fa';
import QuestionPageContainer from "./QuestionPageContainer";
import SamplePageContainer from "./SamplePageContainer";

class FormularSite extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    qCtrl() {
        return this.props.qctrl;
    }


    render() {
        const isFullscreen = this.qCtrl().isFullscreenQuestion();

        return (
          <div className="formularsite p-4 w-full mx-auto grid grid-cols-12">
              {/* QuestionPageContainer */}
              <div id="questionwrapper" className="questionwrapper col-span-12 md:col-span-5 bg-dark-400">
                  <QuestionPageContainer {...this.props} />
              </div>

              {/* SamplePageContainer */}
              {!isFullscreen && (
                <div className="samplecontainer col-span-12 md:col-span-7 bg-black">
                    <SamplePageContainer {...this.props} />
                </div>
              )}
          </div>
        );
    }

};

FormularSite.propTypes = {

};


export default FormularSite;
