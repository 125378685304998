import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FaLessThan } from 'react-icons/fa';

class ProjectNavigation extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    getCategories() {
        return this.props.categories;
    }

    qCtrl() {
        return this.props.qctrl;
    }

    getCustomerNameFromZoho() {
        const zohoCustomerData = this.qCtrl().getZohoCustomerData();
        if (zohoCustomerData) {
            return `${zohoCustomerData["company"]} - ${zohoCustomerData["firstname"]} ${zohoCustomerData["surname"]}`;
        }
        return null;
    }

    showResultsPage(e) {
        this.props.onResultsPageClicked(e);
        e.stopPropagation();
        e.preventDefault();
        return false;
    }

    render() {
        return (
          <div className="relative text-white shadow-md p-4 mb-2">
              <div className="absolute top-0 left-0 w-full h-full opacity-50 bg-gradient-to-r from-blue-500 to-purple-600"></div>
              <div className="relative z-10">
                  <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-2">
                          <FaLessThan size={14} />
                          <span className="font-bold">Kunde:</span>
                          <span>{this.getCustomerNameFromZoho()}</span>
                          <span>({this.props.name})</span>
                      </div>
                      <div>
                          <a
                            href="#"
                            onClick={(e) => this.showResultsPage(e)}
                            className="text-blue-200 hover:text-blue-100"
                          >
                              Zusammenfassung
                          </a>
                      </div>
                  </div>

              </div>
          </div>
        );
    }
}

ProjectNavigation.propTypes = {
    categories: PropTypes.array,
    qctrl: PropTypes.object,
    name: PropTypes.string,
    onResultsPageClicked: PropTypes.func.isRequired,
};

export default ProjectNavigation;
