import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FaCheckCircle } from 'react-icons/fa';
import CommunicationComponent from "../communication/CommunicationComponent";
import Handlebars from "handlebars";

class SubNavigation extends CommunicationComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    getCategories() {
        return this.props.categories;
    }

    qCtrl() {
        return this.props.qctrl;
    }

    clickOnChildCategory(e, c) {
        e.preventDefault(); // Prevent default action for anchor tags or other clickable elements
        this.fireEvent("pageChanged");
        this.qCtrl().setCurrentQuestionPage(c);
    }

    renderChildCategories() {
        const category = this.qCtrl().getCurrentCategory();
        const list = this.qCtrl().getCurrentSubcategories().map((c) => {
            const visible = this.qCtrl().isVisible(c);
            const clickable = this.qCtrl().isClickable(c);
            const isActive = this.qCtrl().getCurrentSubCategory().id === c.id;
            const answered = this.qCtrl().hasAnswerForQuestionOrPage(c);

            const indicatorColor = answered ? "text-green-500 dark:text-green-400" : "text-gray-400 dark:text-gray-600";
            const indicatorIcon = answered ? <FaCheckCircle size={11} className={"inline"} /> : null;

            if (!visible) { return null; }

            return (
              <li key={c.id} className={`flex items-center cursor-pointer ${isActive ? "text-blue-600 dark:text-blue-500" : ""}`}
                  onClick={(e) => clickable ? this.clickOnChildCategory(e, c) : null}>
                    <span className={`flex items-center justify-center w-5 h-5 me-2 text-xs border border-gray-500 rounded-full shrink-0 dark:border-gray-400`}>
                        {c.id}
                    </span>
                  {this.renderName(c)}
                  {isActive && (
                    <svg className="w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 10">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m7 9 4-4-4-4M1 9l4-4-4-4"/>
                    </svg>
                  )}
              </li>
            );
        });

        return (
          <ol className="flex items-center w-full p-3 space-x-2 text-sm font-medium text-center text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm dark:text-gray-400 sm:text-base dark:bg-gray-800 dark:border-gray-700 sm:p-4 sm:space-x-4 rtl:space-x-reverse">
              {list}
          </ol>
        );
    }

    renderName(c) {
        try {
            const name = c.name;
            const template = Handlebars.compile(name ? name : "");

            return template({ questions: c.elements });
        } catch (e) {
            console.error(e);
        }
        return c.name;
    }

    render() {
        return (
          <div className="flex flex-row">
              <div className="flex-1 flex flex-col">
                  {this.renderChildCategories()}
              </div>
          </div>
        );
    }
}

SubNavigation.propTypes = {
    categories: PropTypes.array,
    qctrl: PropTypes.object,
};

export default SubNavigation;
