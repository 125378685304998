import React, { Component } from 'react';
import PropTypes from 'prop-types';
import QuestionType from "./QuestionType";
import Input from "reactstrap/lib/Input";
import { FaCheck } from 'react-icons/fa';
import Error from "../errors/Error";

class QuestionDomain extends QuestionType {
    constructor(props) {
        super(props);
        this.state = {
            validationError: null
        };
    }

    getTextValue() {
        let txt = this.getValue();
        if (txt == null && this.hasVorauswahl()) {
            txt = this.getVorauswahl();
        }
        return txt;
    }

    validateUrl(url) {
        const noScheme = /^[\w.-]+(?:\.[\w\.-]+)+[\w\-\._%~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(url);
        if (noScheme) {
            if (url.startsWith("www.")) {
                return false;
            }
            return true;
        }
        return false;
    }

    setAndCheckValue(value) {
        this.setValue(value);
        if (this.validateUrl(value)) {
            this.setState({validationError: null});
        }
        else {
            this.setState({validationError: "Bitte gib die Domain in der Form 'xyz.de' an - ohne http/https und ohne www"});
        }
    }

    renderValidationErrors() {
        if (this.state.validationError) {
            return <Error error={{text: this.state.validationError, missing: true}} />;
        }
        return null;
    }

    render() {
        return (
              <div>
                  <div className="md:col-span-12">
                      {this.isVorauswahl() && <FaCheck className="vorauswahl_check" onClick={(e) => this.onVorauswahlCheckClicked(e)} />}
                      <Input
                        type="text"
                        value={this.getTextValue()}
                        disabled={this.isFinished() || this.isDisabled()}
                        placeholder={this.placeholder()}
                        onChange={(e) => this.setAndCheckValue(e.target.value)}
                        name="text"
                        className={"mb-2 block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"}
                      />
                      {this.renderValidationErrors()}
                      {this.renderErrors()}
                  </div>
          </div>
        );
    }
}

QuestionDomain.propTypes = {
    darkMode: PropTypes.bool,
};

QuestionDomain.defaultProps = {
    darkMode: false,
};

export default QuestionDomain;
