import React, { Component } from "react";
import {
  FaComment,
  FaPen,
  FaCheck,
  FaDownload,
  FaTrash
} from "react-icons/fa";
import { FileManager, FileUploader } from "reactjs-file-uploader";
import { API_URL, UPLOAD_API } from "../config";
import Universal20Service, { getAccessToken } from "../services/Universal20Service";
import axios from "axios";
import QuestionFile from "./questiontypes/QuestionFile";

export default class FloatingCommentButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      files: [],
    };

    this.uploadFile = this.uploadFile.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.onFileUploaded = this.onFileUploaded.bind(this);
    this.setCurrentCommentFiles = this.setCurrentCommentFiles.bind(this);
  }

  onClickButton(e) {
    this.setState({
      open: !this.state.open
    });
  }

  hasComment() {
    return this.props.qctrl.hasComment();
  }

  setCurrentCommentFiles(files) {
    return this.props.qctrl.setCurrentCommentFiles(files);
  }

  setCurrentCommentText(text) {
    return this.props.qctrl.setCurrentCommentText(text);
  }

  getCurrentQuestion() {
    return this.props.qctrl.getCurrentQuestion();
  }

  getCurrentComment() {
    if (this.props.qctrl) {
      const c = this.props.qctrl.getCurrentComment();
      if (c) {
        return c;
      }
    }
    return {
      text: "",
      files: []
    };
  }

  getAllUploads() {
    let value = this.getCurrentComment().files;
    return value;
  }

  hasUploadedFiles() {
    return this.getAllUploads().length > 0;
  }

  qCtrl() {
    return this.props.qctrl;
  }

  onFileSelected(event) {
    const fileList = Array.from(event.target.files);
    const mappedList = fileList.map((file) => {
      return {
        filename: file.name,
        fileInfo: file,
        id: QuestionFile.buildID(file)
      };
    });

    this.setState({ files: this.state.files.concat(mappedList) });
  }

  onFileUploaded(file, idata, data) {
    const responsedata = data.response;
    const filecreated = {
      name: file.filename,
      type: responsedata.files[0].mimeType,
      url: responsedata.files[0].url,
      id: responsedata.files[0]._id
    };
    this.setState({
      files: this.state.files.filter((f) => f.id !== file.id),
    });
    let all = this.getCurrentComment().files;
    if (!all) {
      all = [];
    }
    all.push(filecreated);
    this.setCurrentCommentFiles(all);
  }

  renderAllUploadedFiles() {
    return this.getAllUploads().map((u) => (
      <div className="flex justify-between items-center bg-gray-800 p-2 rounded my-2">
        <div className="text-white">{u.name}</div>
        <div className="flex space-x-2">
          <a href={API_URL + u.url} target="_blank" rel="noopener noreferrer" className="text-blue-500">
            <FaDownload />
          </a>
          <button onClick={(e) => this.deleteFile(u)} className="text-red-500">
            <FaTrash />
          </button>
        </div>
      </div>
    ));
  }

  uploadFile(file) {
    return (
      <FileUploader
        key={file.key}
        file={file}
        headers={{ Authorization: "Bearer " + getAccessToken() }}
        onDownloadComplete={(d, e) => this.onFileUploaded(file, d, e)}
        autoUpload={true}
        url={API_URL + UPLOAD_API + "/" + this.qCtrl().getId() + "/" + Universal20Service.getHostnameSubdomain()}
        formData={{
          file: file.fileInfo,
          id: file.id
        }}
        readFile
      >
        {QuestionFile.fileProgress}
      </FileUploader>
    );
  }

  downloadFile(file) {
    const uri = API_URL + "/attachments" + file.id;
    var link = document.createElement("a", { target: "_blank" });
    link.setAttribute('download', file.name);
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  deleteFile(file) {
    if (confirm("Sind sie sicher dass der Anhang gelöscht werden soll?")) {
      const files = this.getAllUploads().filter((f) => f.id !== file.id);
      axios.delete(API_URL + "/api/configurators/upload/" + file.id, {
        headers: {
          Authorization: "Bearer " + getAccessToken()
        }
      }).then(() => {
        this.setCurrentCommentFiles(files);
      });
      this.setCurrentCommentFiles(files);
    }
  }

  uploadFiles(files) {
    return files.map((file) => this.uploadFile(file));
  }

  getUploadingFiles() {
    return this.state.files;
  }

  onClickUploadFile(e) {
    document.getElementById("comment-uploadinput").click();
  }

  render() {
    const hasComment = this.hasComment();
    return (
      <div className="fixed bottom-4 right-4">
        <div className={`transition-transform transform ${this.state.open ? 'scale-100' : 'scale-0'} bg-gray-900 text-white p-4 rounded-lg shadow-lg`}>
          <textarea
            disabled={this.props.qctrl.isFinished()}
            onChange={(e) => this.setCurrentCommentText(e.target.value)}
            value={this.getCurrentComment().text}
            rows={4}
            className="w-full p-2 mb-2 bg-gray-800 rounded"
          />
          <div className="flex flex-col space-y-2">
            <input
              type="file"
              onChange={(event) => this.onFileSelected(event)}
              multiple
              id="comment-uploadinput"
              style={{ display: "none" }}
            />
            {!this.props.qctrl.isFinished() && (
              <button onClick={(e) => this.onClickUploadFile(e)} className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600">
                Datei hochladen
              </button>
            )}
            <div>
              <FileManager files={this.getUploadingFiles()}>
                {this.uploadFiles}
              </FileManager>
            </div>
            <div>
              {this.renderAllUploadedFiles()}
            </div>
          </div>
        </div>
        <div
          onClick={(e) => this.onClickButton(e)}
          className={`flex items-center justify-center w-12 h-12 rounded-full cursor-pointer ml-auto ${hasComment ? 'bg-blue-500 text-white' : 'bg-gray-500 text-gray-200'}`}
        >
          {!this.state.open ? (
            <div className="relative">
              <FaComment />
              {hasComment && <div className="absolute top-0 right-0 w-4 h-4 rounded-full flex items-center justify-center text-xs"><FaPen size={10} /></div>}
            </div>
          ) : (
            <FaCheck />
          )}
        </div>
      </div>
    );
  }
}
